<template>
  <div>
    <h1 class="my-4">Модерация инфопродуктов</h1>
    <b-form-radio-group
      class="w-100 w-sm-auto"
      buttons
      :options="infoProductsOptions.actions.POST.moderation_status.choices"
      value-field="value"
      text-field="display_name"
      button-variant="outline-default"
      v-model="filterParams.moderation_status"
    ></b-form-radio-group>
    <div class="card-wrapper mt-5" v-if="infoproducts">
      <info-product-moderation-card
        :infoproduct="infoproduct"
        v-for="infoproduct in infoproducts"
        :key="infoproduct.id"
        style="max-width: 400px"
      ></info-product-moderation-card>
    </div>
    <loader class="mx-auto" v-else></loader>
  </div>
</template>

<script>
import producing from '@main/api/producing';
import { mapState } from 'vuex';
import InfoProduct from './InfoProduct.vue';
import Loader from '../Loader.vue';
import InfoProductModerationCard from './InfoProductModerationCard.vue';

export default {
  components: { Loader, InfoProductModerationCard },
  data: () => ({
    infoproducts: null,
    filterParams: {
      moderation_status: 1,
    },
  }),
  watch: {
    filterParams: {
      deep: true,
      async handler(val) {
        await this.updateData();
      },
    },
  },
  computed: {
    ...mapState('producing', ['infoProductsOptions']),
  },
  methods: {
    async updateData() {
      this.infoproducts = null;
      const products = await producing.infoProducts.moderation.list(this.filterParams);
      this.infoproducts = products.results;
    },
    async updateProduct(data) {
      await producing.infoProducts.moderation.update(data);
      await this.updateData();
    },
  },
  async mounted() {
    await this.updateData();
  },
};
</script>

<style lang="scss" scoped></style>
