<template>
  <div class="hovering__pad position-relative">
    <template v-if="!status">
      <b-form class="p-4 overflow-auto" style="height: 500px"
        ><multi-step-window :steps="steps" :currentStep="step">
          <template #header
            ><div class="mb-4 d-flex justify-content-between align-items-center">
              <b-button
                variant="link"
                target="_blank"
                size="lg"
                :href="`https://instagram.com/${
                  infoproduct.blogger ? infoproduct.blogger.instaname : infoproduct.instaname
                }`"
                >{{
                  infoproduct.blogger ? infoproduct.blogger.instaname : infoproduct.instaname
                }}</b-button
              >
              <div style="line-height: 14px !important">
                <p class="fs-12" style="color: #b4bdc1">
                  {{ infoproduct.created_at | moment('DD.MM.YY') }}
                </p>
                <p class="fs-12" style="color: #b4bdc1">#{{ infoproduct.id }}</p>
              </div>
            </div>
          </template>
          <template #step1
            ><b-form-group label="Темы аккаунта">
              <multiselect
                v-if="infoproduct.blogger"
                :options="tags"
                placeholder="Выберите темы"
                select-label=""
                deselectLabel=""
                selectedLabel=""
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="true"
                v-model="infoproduct.blogger.tags"
              >
                <span slot="noResult">Тема не найдена</span>
                <template #tagPlaceholder>Выберите тему</template> </multiselect
              ><multiselect
                v-else
                :options="tags"
                placeholder="Выберите темы"
                select-label=""
                deselectLabel=""
                selectedLabel=""
                label="name"
                track-by="id"
                :searchable="true"
                :multiple="true"
                v-model="infoproduct.tags"
              >
                <span slot="noResult">Тема не найдена</span>
                <template #tagPlaceholder>Выберите тему</template>
              </multiselect></b-form-group
            >
            <div class="mb-5">
              <h5 class="required-field-star mb-2">Выберите вид инфопродукта:</h5>
              <div class="align-items-start d-flex flex-wrap justify-content-between">
                <template v-for="option in infoProductsOptions.actions.POST.kind.choices">
                  <LeftPointedRadioButton
                    :key="`${option.value}-o124kirTpFrm43s`"
                    :variant="option.value === infoproduct.kind ? 'primary' : 'outline-default'"
                    @click="
                      () => {
                        infoproduct.kind = option.value;
                      }
                    "
                    class="mr-1 mt-2 fmt-rd ph-36 text-left"
                    style="line-height: 13px"
                  >
                    {{ option.display_name }}
                  </LeftPointedRadioButton>
                </template>
              </div>
            </div>
            <b-form-group label="Название и короткое описание инфопродукта."
              ><b-form-textarea
                v-model="infoproduct.description"
                style="min-height: 180px"
              ></b-form-textarea></b-form-group></template
          ><template #step2>
            <div class="mb-5">
              <h5 class="mb-3">Запуск на своих охватах или с привлечением?</h5>

              <div class="d-block d-xsm-flex">
                <left-pointed-radio-button
                  class="w-100 w-xsm-50 mr-xsm-2"
                  style="line-height: 11px; height: 40px"
                  :variant="
                    !infoproduct.standalone && typeof infoproduct.standalone !== 'object'
                      ? 'primary'
                      : 'outline-default'
                  "
                  @click="infoproduct.standalone = false"
                >
                  Свои охваты
                </left-pointed-radio-button>
                <left-pointed-radio-button
                  class="w-100 w-xsm-50 ml-xsm-2 mt-2 mt-xsm-0"
                  style="line-height: 11px; height: 40px"
                  :variant="infoproduct.standalone ? 'primary' : 'outline-default'"
                  @click="infoproduct.standalone = true"
                >
                  С привлечением
                </left-pointed-radio-button>
              </div>
            </div>
            <div class="mb-5">
              <h5 class="required-field-star mb-2">Желаемый месяц старта продаж.</h5>
              <div class="align-items-start d-flex flex-wrap justify-content-between">
                <template v-for="option in infoProductsOptions.actions.POST.month.choices">
                  <LeftPointedRadioButton
                    :key="`${option.value}-o124kirTpFrm43s`"
                    :variant="option.value === infoproduct.month ? 'primary' : 'outline-default'"
                    @click="
                      () => {
                        infoproduct.month = option.value;
                      }
                    "
                    class="mr-1 mt-2 fmt-rd ph-36 text-left"
                    style="line-height: 13px"
                  >
                    {{ option.display_name }}
                  </LeftPointedRadioButton>
                </template>
              </div>
            </div></template
          ><template #step3>
            <div class="mb-5">
              <h5 class="required-field-star mb-2">
                Укажите текущую стадию создания инфопродукта.
              </h5>
              <div>
                <template v-for="option in infoProductsOptions.actions.POST.stage.choices">
                  <LeftPointedRadioButton
                    :key="`${option.value}-o124kirTpFrm43s`"
                    :variant="option.value === infoproduct.stage ? 'primary' : 'outline-default'"
                    @click="
                      () => {
                        infoproduct.stage = option.value;
                      }
                    "
                    class="mr-1 mt-2 w-100 ph-36 text-left"
                    style="line-height: 13px; height: 40px !important"
                  >
                    {{ option.display_name }}
                  </LeftPointedRadioButton>
                </template>
              </div>
            </div>
            <div>
              <h5 class="required-field-star mb-2">
                Нужно ли привлекать экспертов по теме инфопродукта для его создания?
              </h5>
              <div class="align-items-start d-flex flex-wrap justify-content-between">
                <template
                  v-for="option in [
                    { display_name: 'Да', value: true },
                    { display_name: 'Нет', value: false },
                  ]"
                >
                  <LeftPointedRadioButton
                    :key="`${option.value}-o124kirTpFrm43s`"
                    :variant="
                      option.value === infoproduct.involve_experts ? 'primary' : 'outline-default'
                    "
                    @click="
                      () => {
                        infoproduct.involve_experts = option.value;
                      }
                    "
                    class="mr-1 mt-2 fmt-rd ph-36 text-left"
                    style="line-height: 13px; height: 40px !important"
                  >
                    {{ option.display_name }}
                  </LeftPointedRadioButton>
                </template>
              </div>
            </div></template
          ><template #step4>
            <div
              v-if="infoproduct.experience && infoproduct.experience === 2"
              class="input__symbol--count position-relative mb-5"
              :symbolCount="(infoproduct.previous_products || '').length"
              :symbolLimit="220"
            >
              <h5 class="mb-2">Что запускали?</h5>
              <b-form-textarea
                maxlength="220"
                style="height: 180px"
                v-model="infoproduct.previous_products"
              ></b-form-textarea>
            </div>
            <div v-if="infoproduct.experience && infoproduct.experience === 2" class="mb-5">
              <h5 class="required-field-star mb-2">
                На какую сумму в рублях был ваш самый крупный запуск?
              </h5>
              <div class="rouble-inp position-relative">
                <b-form-input
                  type="number"
                  v-model="infoproduct.max_price"
                  class="w-100 ph-36"
                  placeholder="Введите сумму"
                ></b-form-input>
              </div></div></template
          ><template #step5>
            <div
              v-if="infoproduct.experience && infoproduct.experience === 2"
              class="input__symbol--count position-relative mb-5"
              :symbolCount="(infoproduct.refuse_products_reasons || '').length"
              :symbolLimit="400"
            >
              <h5 class="required-field-star mb-2">
                Если запускали свои инфопродукты с продюсерами, то укажите, почему от них
                отказались?
              </h5>
              <b-textarea
                maxlength="400"
                v-model="infoproduct.refuse_products_reasons"
                style="height: 180px"
              ></b-textarea></div
          ></template>
          <template #step6>
            <div>
              <h5 class="required-field-star mb-2">Период публикации</h5>
              <div class="align-items-start d-flex flex-wrap justify-content-between">
                <template
                  v-for="option in [
                    { display_name: '1 месяц', value: 1 },
                    { display_name: '2 месяца', value: 2 },
                    { display_name: '3 месяца', value: 3 },
                  ]"
                >
                  <LeftPointedRadioButton
                    :key="`${option.value}-o124kirTpFrm43s`"
                    :variant="
                      option.value === infoproduct.publication_period
                        ? 'primary'
                        : 'outline-default'
                    "
                    @click="
                      () => {
                        infoproduct.publication_period = option.value;
                      }
                    "
                    class="mr-1 mt-2 fmt-rd ph-36 text-left"
                    style="line-height: 13px; height: 40px !important"
                  >
                    {{ option.display_name }}
                  </LeftPointedRadioButton>
                </template>
              </div>
            </div>
            <b-form-checkbox class="mt-5" size="lg" v-model="infoproduct.tg_publish"
              >Публикация в рассылке Telegram</b-form-checkbox
            ></template
          ></multi-step-window
        ></b-form
      >
      <div
        style="
          border-top: 1px solid #d5dfe4;
          background-color: #f8f8f8;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        "
        class="d-flex justify-content-between p-4"
      >
        <b-button
          class="px-4"
          @click="
            () => {
              if (step > 1) {
                if (step === 6 && !(infoproduct.experience && infoproduct.experience === 2)) {
                  step = 3;
                  return;
                }
                step -= 1;
              }
            }
          "
          variant="outline-default"
          >{{ step !== 6 ? 'Назад' : '←' }}</b-button
        >
        <b-button
          class="px-4"
          variant="outline-default"
          v-if="step === 6"
          @click="
            () => {
              updateProduct({ moderation_status: 3 });
              status = 3;
            }
          "
          >Отклонить</b-button
        >
        <b-button
          v-if="step < 6"
          class="px-4"
          @click="
            if (step < steps) {
              if (step === 3 && !(infoproduct.experience && infoproduct.experience === 2)) {
                step = 6;
                return;
              }
              step += 1;
            }
          "
          variant="primary"
          >Далее</b-button
        ><b-button
          class="px-4"
          v-else
          variant="yellow"
          @click="
            () => {
              updateProduct({ moderation_status: 2 });
              status = 2;
            }
          "
          >Одобрить</b-button
        >
      </div>
    </template>
    <template v-else
      ><div class="p-4" style="height: 575px">
        <b-button
          variant="link"
          target="_blank"
          size="lg"
          :href="`https://instagram.com/${infoproduct.blogger.instaname}`"
          >{{ infoproduct.blogger.instaname }}</b-button
        >
        <div class="h-100 d-flex flex-column align-items-center justify-content-center pb-5">
          <template v-if="status === 2">
            <div
              class="d-flex justify-content-center align-items-center mb-3"
              style="border-radius: 50%; width: 72px; height: 72px; border: 3px solid green"
            >
              <img src="@main/assets/img/svg/thick_check.svg" alt="" />
            </div>
            <p class="fs-16 mb-3">Заявка одобрена</p>
          </template>
          <template v-else>
            <div
              class="d-flex justify-content-center align-items-center mb-3"
              style="border-radius: 50%; width: 72px; height: 72px; border: 3px solid red"
            >
              <img src="@main/assets/img/svg/thick_red_cross.svg" alt="" />
            </div>
            <p class="fs-16 mb-3">Заявка отклонена</p>
          </template>
          <b-button class="px-4" @click="resetStatus" variant="outline-default">Отменить</b-button>
        </div>
        <p class="position-absolute gray-text" style="bottom: 10px">
          Заявка перемещена во вкладку
          {{
            infoProductsOptions.actions.POST.moderation_status.choices.filter(
              (val) => val.value === status
            )[0].display_name
          }}
        </p>
      </div></template
    >
  </div>
</template>

<script>
import producing from '@main/api/producing';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';
import LeftPointedRadioButton from '../reusable/elements/buttons/LeftPointedRadioButton.vue';
import MultiStepWindow from '../reusable/windows/MultiStepWindow.vue';

export default {
  props: {
    infoproduct: Object,
  },
  data: () => ({
    steps: 6,
    previousStatus: null,
    status: null,
    step: 1,
  }),
  computed: {
    ...mapState(['tags', 'currentUser']),
    ...mapState('producing', ['infoProductsOptions']),
  },
  components: {
    Multiselect,
    LeftPointedRadioButton,
    MultiStepWindow,
  },
  methods: {
    async updateProduct(extras) {
      const result = await producing.infoProducts.moderation.update({
        ...this.infoproduct,
        ...extras,
      });
      this.infoproduct = result;
    },
    async resetStatus() {
      this.status = null;
      await this.updateProduct({ moderation_status: this.previousStatus });
    },
  },
  mounted() {
    this.previousStatus = this.infoproduct.moderation_status;
  },
};
</script>

<style lang="scss" scoped>
.fmt-rd {
  width: 48% !important;
  min-width: 110px !important;
  height: 40px !important;
}
</style>
