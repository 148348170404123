<template>
  <div>
    <DefaultMobileTable
      :trClass="['d-flex my-4 vertical-align-middle']"
      :thClass="['infoprodth']"
      :tdClass="['infoprodtd']"
      class="tbl p-0"
      :fields="fields"
      :item="{
        ...infoproduct,
        blogger: {
          ...infoproduct.blogger,
          reviews_stat_main: infoproduct.blogger ? infoproduct.blogger.reviews_stat : null,
        },
      }"
    >
      <template #cell(instaname)-custom="data">
        <div class="d-flex justify-content-between mb-4">
          <b-button
            v-if="!data.item.blogger || !data.item.blogger.instaname"
            size="lg"
            variant="link"
            >Ник блогера скрыт</b-button
          >
          <b-button size="lg" variant="link" v-else>{{ data.item.blogger.instaname }}</b-button>
          <p class="gray-text fs-12">№{{ data.item.id }}</p>
        </div>
      </template>
      <template v-if="infoproduct.blogger" #cell(blogger)-custom="data">
        <div class="my-2">
          <hr style="color: #d5dfe4" />
        </div>
        <DefaultMobileTable
          :trClass="['d-flex my-4']"
          :thClass="['infoprodth']"
          :tdClass="['infoprodtd']"
          class="tbl p-0"
          :fields="bloggerFields"
          :item="data.item.blogger"
          ><template #cell(reviews_stat_main)="data"
            ><div v-if="data.value.reviews_count">
              <p>{{ `${data.value.reviews_count}+ отзывов` }}</p>
              <p class="d-inline-block">{{ `${data.value.rate__avg} из 10` }}</p>
              <img class="rev_star d-inline-block" src="@main/assets/img/svg/star.svg" alt="" />
            </div>
            <p v-else>Нет отзывов</p></template
          ><template #cell(main_audience)-custom="data">
            <th class="infoprodth w-100">🗿 Аудитория блогера</th>
            <div class="mt-2 mb-4">
              <p class="mb-2">
                Женщины ≈ {{ data.item.females_percent }}%, возраст
                {{
                  bloggerOptions.actions.POST.audiences.child.children.age_group.choices.find(
                    (val) => val.value === data.value.age_group
                  ).display_name
                }}
                ≈ {{ data.value.age_group_percent }}%
              </p>
              <p v-if="data.item.main_country.coverage">
                {{ data.item.main_country ? data.item.main_country.country.name : '?' }} ≈
                {{ data.item.main_country.coverage }}%
              </p>
            </div></template
          >
          <template #cell(blockcheck)-custom="data"
            ><th>{{ data.label }}</th>
            <td>Не обнаружен</td></template
          >
          <template #cell(coverage_screen)="data"
            ><b-modal class="w-100 h-100" :ref="`csc${data.item.id}`"
              ><img :src="data.value.full_size" alt="" /></b-modal
            ><b-button variant="outline-default" @click="$refs[`csc${data.item.id}`].show()"
              >Показать</b-button
            ></template
          ><template #cell(posts_coverage_screen)="data"
            ><b-modal class="w-100 h-100" :ref="`pcsc${data.item.id}`"
              ><img :src="data.value.full_size" alt="" /></b-modal
            ><b-button variant="outline-default" @click="$refs[`pcsc${data.item.id}`].show()"
              >Показать</b-button
            ></template
          ></DefaultMobileTable
        >
      </template>
    </DefaultMobileTable>
    <template v-if="!showDetail">
      <b-button v-if="!detail" @click="detail = true" variant="link"
        >Показать всю статистику блогера</b-button
      >
      <b-button @click="detail = false" v-else variant="link">Скрыть статистику</b-button>
    </template>
    <div v-if="!$scopedSlots.footer" class="d-flex justify-content-between mt-4">
      <b-button
        @click="$emit('toggle-hidden', infoproduct.id)"
        style="width: 28%"
        variant="outline-default"
        class="ph-42"
        >Скрыть</b-button
      >
      <b-button
        @click="$emit('become-producer', infoproduct.id)"
        style="width: 70%"
        variant="yellow"
        class="ph-42"
        >Стать продюсером</b-button
      >
    </div>
    <slot name="footer" v-else></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TableFieldManageMixin from '../../mixins/tables/TableFieldManageMixin.vue';
import DefaultMobileTable from '../reusable/tables/DefaultMobileTable.vue';

export default {
  components: { DefaultMobileTable },
  props: {
    infoproduct: {
      type: Object,
      required: true,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    moderation: { type: Boolean, default: false },
  },
  data: () => ({
    detail: false,
  }),
  mixins: [TableFieldManageMixin],
  computed: {
    ...mapState('producing', ['infoProductsOptions']),
    ...mapState(['currentUser', 'bloggerOptions', 'tags']),
    fields() {
      return this.processFields(
        [
          {
            key: 'instaname',
            type: 'other',
          },
          {
            key: 'description',
            label: 'Что нужно создать и запустить',
            blockrender: true,
            trClass: 'd-block mb-3',
            thClass: 'mb-1 gray-text',
          },
          {
            key: 'kind',
            label: 'Вид инфопродукта',
            formatter: (value, key, item) => {
              return this.infoProductsOptions.actions.POST.kind.choices.find(
                (val) => value === val.value
              ).display_name;
            },
          },
          {
            key: 'month',
            label: 'Желаемый месяц запуска',
            formatter: (value, key, item) => {
              return this.infoProductsOptions.actions.POST.month.choices.find(
                (val) => value === val.value
              ).display_name;
            },
          },
          {
            key: 'involve_experts',
            label: 'Нужно ли искать экспертов',
            formatter: (value, key, item) => {
              return value ? 'Да' : 'Нет';
            },
          },
          {
            label: 'Запуск на своих охватах',
            key: 'standalone',
            formatter: (value, key, item) => {
              return value ? 'Да' : 'Нет';
            },
          },
          {
            label: 'Опыт запуска инфопродукта',
            key: 'experience',
            formatter: (value, key, item) => {
              return this.infoProductsOptions.actions.POST.experience.choices.find(
                (val) => value === val.value
              ).display_name;
            },
          },
          {
            label: 'Стадия создания продукта',
            key: 'stage',
            formatter: (value, key, item) => {
              return this.infoProductsOptions.actions.POST.stage.choices.find(
                (val) => value === val.value
              ).display_name;
            },
          },
          {
            label: 'Объемы запуска предыдущих продуктов',
            key: 'max_price',
            formatter: (value, key, item) => {
              return value ? `До ${value}₽` : 'Предыдущих запусков не было';
            },
          },
          {
            key: 'blogger',
            type: 'other',
          },
          this.infoproduct.previous_products
            ? {
                ifDetail: true,
                key: 'previous_products',
                label: 'Прошлые инфопродукты',
                blockrender: true,
                trClass: 'd-block mb-3',
                thClass: 'mb-1 gray-text',
              }
            : {},
          this.moderation && this.infoproduct.refuse_products_reasons
            ? {
                key: 'refuse_products_reasons',
                label: 'Почему отказались от прошлых продюсеров',
                blockrender: true,
                trClass: 'd-block mb-3',
                thClass: 'mb-1 gray-text',
              }
            : {},
          this.infoproduct.coverage_min
            ? {
                key: 'coverage_min',
                label: 'Охваты сторис',
                formatter: (value, key, item) => {
                  return `${item.coverage_min} — ${item.coverage_max}`;
                },
              }
            : {},
          this.infoproduct.tags.length
            ? {
                ifDetail: true,
                key: 'tags',
                label: '✅ Темы блогера',
                blockrender: true,
                trClass: 'd-block mb-3',
                thClass: 'mb-1 gray-text',
                formatter: (val, key, item) => {
                  return val
                    .slice(0, 1)
                    .map((tag) => tag.name)
                    .join(', ');
                },
              }
            : {},
        ].filter((val) => {
          if (val.ifDetail && !this.detail && !this.showDetail) {
            return;
          }
          return val;
        })
      );
    },
    bloggerFields() {
      return this.processFields(
        [
          {
            key: 'coverage_min',
            label: 'Охваты сторис',
            formatter: (value, key, item) => {
              return `${item.coverage_min} — ${item.coverage_max}`;
            },
          },

          {
            key: 'coverage_screen',
            label: 'Скрин охватов сторис',
          },
          this.infoproduct.blogger.coverage_posts_min && this.infoproduct.blogger.coverage_posts_max
            ? {
                key: 'coverage_posts_min',
                label: 'Охваты постов',
                formatter: (value, key, item) => {
                  return `${item.coverage_posts_min} — ${item.coverage_posts_max}`;
                },
              }
            : {},
          {
            key: 'posts_coverage_screen',
            label: 'Скрин охватов постов',
          },
          {
            ifDetail: true,
            key: 'reviews_stat',
            label: 'Охваты cторис из отзывов',
            formatter: (value, key, item) => {
              return item.reviews_stat.largest_reach__min
                ? `${item.reviews_stat.largest_reach__min} — ${item.reviews_stat.largest_reach__max}`
                : 'Нет отзывов';
            },
          },
          { ifDetail: true, key: 'reviews_stat_main', label: 'Отзывы на сайте' },
          this.infoproduct.blogger.main_audience.age_group_percent &&
          this.infoproduct.blogger.main_country.coverage
            ? { ifDetail: true, key: 'main_audience', label: 'Аудитория блогера', type: 'other' }
            : {},
          {
            ifDetail: true,
            key: 'tags',
            label: '✅ Темы блогера',
            blockrender: true,
            trClass: 'd-block mb-3',
            thClass: 'mb-1 gray-text',
            formatter: (val, key, item) => {
              return val
                .slice(0, 1)
                .map((tag) => tag.name)
                .join(', ');
            },
          },
          {
            key: 'blockcheck',
            ifDetail: true,
            label: 'Проверка по ЧС и 30 чатам активностей',
          },
        ].filter((val) => {
          if (val.ifDetail && !this.detail && !this.showDetail) {
            return;
          }
          return val;
        })
      );
    },
  },
};
</script>

<style>
.infoprodth {
  min-width: 140px;
  margin-right: 100px;
  width: 140px;
  line-height: 16.59px;
  color: #6a7b83;
}
@media (max-width: 500px) {
  .infoprodth {
    margin-right: calc(40% - 100px);
  }
}
.infoprodtd {
  line-height: 16.59px !important;
}
</style>
<style lang="scss" scoped>
tr {
  margin: 10px;
}
</style>
